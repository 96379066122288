.image {
  /* the image you want to 'watermark' */
  height: 200px;
  /* or whatever, equal to the image you want 'watermarked' */
  width: 200px;
  /* as above */
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
}

.image img {
  /* the actual 'watermark' */
  position: relative;
  top: 0;
  /* or whatever */
  left: 0;
  /* or whatever, position according to taste */
  opacity: 0.8;
  /* Firefox, Chrome, Safari, Opera, IE >= 9 (preview) */
  filter: alpha(opacity=80);
  height: 250%;
  width: 250%;
  left: 250%;
  top: 15%;

  /* for <= IE 8 */
}
