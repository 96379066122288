.containerImg {
  position: relative;
  width: 50%;
}

/* Make the image responsive */
.containerImg img {
  width: 100%;
  height: auto;
  border: "1px solid silver";
  box-shadow: "10px 10px 5px lightblue";
  object-fit: "contain";
}

/* Style the button and place it in the middle of the container/image */
/* .containerImg .Icon {
  border-radius: 50%;
  padding: 0.5em;
  width: 30px;
  height: 30px;
  color: black;
  position: absolute;
  background-color: green;
} */

.containerImg .test:hover {
  color: red;
  cursor: pointer;
}
