#loginContainer {
  background: white !important;
  height: 35% !important;
  width: 35% !important;
  margin-top: 5% !important;
  margin-left: 34% !important;
}
.appLoader {
  width: 20% !important;
  left: 40% !important;
  height: 20% !important;
  top: 40% !important;
}
.td-area {
  width: 27% !important;
}
.rowClass {
  width: 215vh !important;
  margin-left: -8% !important;
}
.collapse,
.collapsing {
  overflow: auto;
  max-height: 50vh;
}
#transationMessage {
  left: 85% !important;
  position: fixed;
  top: 20% !important ;
  z-index: 1000;
  background-color: green;
  color: white;
  width: 350px !important;
}
.lblShowAll {
  padding-top: 8px !important;
  margin-left: -250px !important;
  color: blue;
  display: block;
  width: 580px !important;
}
/* --- for iPhone 6, 7, & 8  ---------- */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  #loginContainer {
    background: white !important;
    width: 100% !important;
    margin-left: 0px !important;
    margin-top: 15% !important;
  }
  .mainContainer {
    margin-top: 15% !important;
  }
  .appLoader {
    width: 80% !important;
    left: 50px !important;
    height: 20% !important;
    top: 40% !important;
  }
  #transationMessage {
    left: 55% !important;
  }
  .lblShowAll {
    display: none;
  }
}
/* --- for Galaxy s8  ---------- */
@media only screen and (min-device-width: 360px) and (max-device-width: 740px) {
  #loginContainer {
    background: white !important;
    width: 100% !important;
    margin-left: 0px !important;
    margin-top: 15% !important;
  }
  #transationMessage {
    left: 53% !important;
  }
  .appLoader {
    width: 100% !important;
    left: 30px !important;
    height: 20% !important;
    top: 40% !important;
  }
  .lblShowAll {
    display: none;
  }
}
/* --- for iphone XR  ---------- */
@media only screen and (min-device-width: 441px) and (max-device-width: 896px) {
  #loginContainer {
    background: white !important;
    width: 100% !important;
    margin-left: 0px !important;
    margin-top: 35% !important;
  }
}
/* --- for ipad  ---------- */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) {
  #loginContainer {
    background: white !important;
    width: 100% !important;
    margin-left: 0px !important;
    margin-top: 25% !important;
    height: 55vh !important;
  }
  .mainContainer {
    margin-top: 7% !important;
  }
  .appLoader {
    width: 100% !important;
    left: 30px !important;
    height: 20% !important;
    top: 40% !important;
  }
  #transationMessage {
    left: 65% !important;
    width: 300px !important;
  }
}
/* --- for ipad mini  ---------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #loginContainer {
    background: white !important;
    width: 100% !important;
    margin-left: 0px !important;
    margin-top: 25% !important;
    height: 62vh !important;
  }
  .mainContainer {
    margin-top: 7% !important;
  }
  .appLoader {
    width: 100% !important;
    left: 30px !important;
    height: 20% !important;
    top: 40% !important;
  }
  #transationMessage {
    left: 75% !important;
    width: 250px !important;
  }
}
